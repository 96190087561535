.pricing-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  .pricing-table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  .pricing-table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }
  
  .pricing-table th,
  .pricing-table td {
    padding: .625em;
    text-align: center;
  }
  
  .pricing-table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 600px) {
    .pricing-table {
      border: 0;
    }
  
    .pricing-table caption {
      font-size: 1.3em;
    }
    
    .pricing-table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    .pricing-table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
      
    }
    
    .pricing-table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    .pricing-table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .pricing-table td:last-child {
      border-bottom: 0;
    }
  }
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #1D6C1D;
  --secondary: #2D9B2D;
  --accent: #61CE70;
  --primary-text: white;
  --secondary-text: black;
}

::-moz-selection {
  color: var(--primary-text);
  background: var(--primary);
}
::selection {
  color: var(--primary-text);
  background: var(--primary);
}

.active {
  border: 0.5px solid var(--accent);
  color: var(--accent);
  font-weight: 600;
}

@media (max-width: 382px) {
  .active {
    border: none;
    background-color: var(--accent);
    color: white;
  }
}


.fc .fc-button {
  background-color: var(--primary);
  border: none;
  color: white; 
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
}

/* Change the background color when hovering over buttons */
.fc .fc-button:hover {
  background-color: var(--secondary);
}

/* Change the background color of the active button */
.fc .fc-button.fc-button-active {
  background-color: var(--secondary); 
}

/* Change the background color of the 'today' button */
.fc .fc-today-button {
  background-color: var(--primary);
}

.fc .fc-today-button:hover {
  background-color: var(--secondary);
}

/* Change the background color of the 'prev' and 'next' buttons */
.fc .fc-prev-button, .fc .fc-next-button {
  background-color: var(--primary);
}

.fc .fc-prev-button:hover, .fc .fc-next-button:hover {
  background-color: var(--secondary);
}

.fc .fc-button-primary:disabled {
  background-color: var(--accent);
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--secondary);
}

.fc .fc-button-primary {
  background-color: var(--primary);
}

.fc-toolbar-title {
  font-weight: bold;
  text-align: center;
}